import React, { useState } from 'react';
import Menu from './Menu';
import Categories from './Categories';
import items from './data.json';
import data from './data.json'
import { useTranslation } from 'react-i18next';
import Anchor from "./anchor/Anchor";

function App() {

  let categories = Object.keys(items);
  const { i18n } = useTranslation();
  const [flag, setFlag] = useState('en');
  const handleChange = () => {
    i18n.changeLanguage(i18n.language == "en" ? "gr" : "en");
    setFlag(i18n.language == "en" ? "gr" : "en");
  }

  return (
    <main>
      <section className="menu section">
        <div className='languageChange'>
          <img src={'./images/' + flag + '.png'} onClick={handleChange} />
        </div>
        <div className="title">
          {/* <h2>Μεταξύ μας</h2> */}
          <div className='logoContainer'>
            <img className='logo' src={'./images/logo.png'} />
            <img className='vareli' src={'./images/vareli_metaximas.png'} />
          </div>
          <div className="underline" />
        </div>
        <Categories categories={categories} />
      </section>
      <section className="section">
        <Menu items={data} />
      </section>
      <Anchor />
    </main>
  );
}

export default App;
