import React, { useState } from 'react';

const ImageContainer = ({ src }) => {
    const [imageExists, setImageExists] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleImageError = () => {
        setImageExists(false);
    };

    const handleImageClick = () => {
        setIsFullscreen(true);
    };

    const handleCloseFullscreen = () => {
        setIsFullscreen(false);
    };


    if (imageExists && src !== undefined) {
        return (<>
            <img src={src} alt={src} className="photo" onError={handleImageError} onClick={handleImageClick} />
            {isFullscreen && (
                <div className="fullscreen-overlay" onClick={handleCloseFullscreen}>
                    <span className="close-button" onClick={handleCloseFullscreen}>&times;</span>
                    <img
                        src={src}
                        alt={src}
                        className="fullscreen-image"
                    />
                </div>
            )}
        </>)
    } else {
        return <div></div>
    }
};

export default ImageContainer;
