import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import gr from "./gr.json"
import en from "./en.json"

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'gr',
        resources: {
            en: {
                translation: en
            },
            gr: {
                translation: gr
            }
        }
    })