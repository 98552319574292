import React from 'react';
import { Element } from 'react-scroll';
import ImageContainer from './ImgComponent';

import { useTranslation } from 'react-i18next';

const Menu = ({ items }) => {
  const { t } = useTranslation();
  const categoryOrder = ['Salad', 'Cheese', 'Meze', 'Soft_Drinks', 'Tsipouro', 'Beer', 'White_Wines', 'Rose_Wines', 'Red_Wines'];

  return (
    <div className="section-center">
      {categoryOrder.map(category => {
        return (
          <Element name={category} key={category} className="category">
            <h3>{t(category)}</h3>
            <ul>
              {items[category].data.map((value, index) => {
                const { name, img, val, price } = value;
                return (
                  <article key={index} className="menu-item">
                    <div className='icon-item'>
                      <ImageContainer src={'./images/' + name + '.jpeg'} />
                      <h4 className='item-title'>{t(name)}</h4>
                      {val &&
                        <div className='item-title-val'>
                          <span>
                            {t(val)}
                          </span>
                        </div>}
                    </div>
                    {price > 0 && <h4 className="price">{price}€</h4>}
                  </article>
                );
              })
              }
            </ul>
          </Element>
        );
      })}
    </div>
  )
};

export default Menu;
