import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const Categories = ({ categories }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('Salad')

  return (
    <div className="btn-container">
      {categories.map((category, index) => {
        return (
          <div className={`filter-btn ${category === selected ? "active" : ""}`} key={category}>
            <Link
              type="button"
              className={'category-text'}
              key={index}
              to={category}
              smooth={true}
              offset={-185}
              duration={500}
              onClick={() => { setSelected(category) }}
            >
              {t(category)}
            </Link>
          </div>

        );
      })}
    </div>
  );
};

export default Categories;
